import { uuid } from "@sanity/uuid";

export const getGAClientId = () => {
  const gaCookie = document?.cookie?.split("; ").find(row => row.startsWith("_ga="));
  if (!gaCookie) {
    return null;
  }
  return gaCookie.split("_ga=")?.[1];
};

export function ensureSessionGUID() {
  let sessionGUID = sessionStorage.getItem("sessionGUID");
  if (!sessionGUID) {
    sessionGUID = uuid();
    sessionStorage.setItem("sessionGUID", sessionGUID);
  }
  return sessionGUID;
}

