import React, { createContext, Dispatch, useMemo, useReducer } from "react";
import { PageRating, RawPageRating } from "../models/pageRating/pageRating";

export enum PageRatingContextActionType {
  LoadingBegin,
  LoadingEnd,
  Update
}

export enum PageRatingState {
  Uninitialized,
  Loading,
  Initialized
}

type PageRatingContextState = {
  state: PageRatingState;
  pageRating: PageRating;
};

export type PageRatingContextAction =
  | {
      type: PageRatingContextActionType.LoadingBegin;
    }
  | {
      type: PageRatingContextActionType.LoadingEnd | PageRatingContextActionType.Update;
      newPageRating: PageRating;
    };

export type PageRatingContextType = readonly [
  PageRatingContextState,
  Dispatch<PageRatingContextAction>
];

interface PageContextProviderProps {
  children?: React.ReactNode;
  pageId: string;
  rawPageRating?: RawPageRating;
}

const reducer = (state: PageRatingContextState, action: PageRatingContextAction) => {
  if (state.pageRating.active) {
    switch (action.type) {
      case PageRatingContextActionType.LoadingBegin:
        return {
          ...state,
          state: PageRatingState.Loading
        };
      case PageRatingContextActionType.LoadingEnd:
        return {
          ...state,
          state: PageRatingState.Initialized,
          pageRating: action.newPageRating
        };
      case PageRatingContextActionType.Update:
        return {
          ...state,
          pageRating: action.newPageRating
        };
    }
  }

  return state;
};

// Dummy defaultValue is required here for "gatsby build" to succeed. The "real" initial value is set below
// with useReducer though.
const PageRatingContext = createContext<PageRatingContextType>([
  {}
] as unknown as PageRatingContextType);

const PageRatingContextProvider: React.FC<PageContextProviderProps> = ({
  children,
  pageId,
  rawPageRating
}) => {
  let pageRating: PageRating;

  if (rawPageRating?.active) {
    const accumulatedRatings = rawPageRating.accumulatedRatings ?? 0;
    const numRatings = rawPageRating.numRatings ?? 0;

    let userRating: number | null = null;

    if (typeof window !== "undefined") {
      const pageRatingStorageKey = `pageRating-${pageId}`;
      const userRatingString = localStorage.getItem(pageRatingStorageKey);

      if (userRatingString) {
        userRating = parseInt(userRatingString);
      }
    }

    pageRating = {
      active: true,
      accumulatedRatings,
      numRatings,
      userRating,
      averageRating: numRatings > 0 ? accumulatedRatings / numRatings : null
    };
  } else {
    pageRating = {
      active: false
    };
  }

  const [state, dispatch] = useReducer(reducer, {
    state: PageRatingState.Uninitialized,
    pageRating
  });

  // TODO: Investigate useMemo
  const memoized = useMemo(() => [state, dispatch] as const, [state, dispatch]);

  return (
    <PageRatingContext.Provider value={memoized}>{children}</PageRatingContext.Provider>
  );
};

export { PageRatingContext, PageRatingContextProvider };
