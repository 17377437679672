import { isNullOrWhiteSpace } from "../shared/string";

const parseTrackingIds = (ids: string | undefined): string[] => {
  if (!ids?.length) {
    return [];
  }

  return ids
    .split(",")
    .map(id => id.trim())
    .filter(id => !isNullOrWhiteSpace(id));
};

const getMaxFileSize = (value: string | undefined): number => {
  // Default max file size for insecure forms is 8 MB because Netlify Forms will cut off
  // the form post for files larger than that.
  const netlifyMaxSize = 8;
  const max = parseInt(value ?? "");
  if (Number.isInteger(max) && max > 0) {
    return max;
  }

  return netlifyMaxSize;
};

const siteConfig = {
  sanityProjectId: process.env.GATSBY_SANITY_PROJECT_ID,
  sanityDataset: process.env.GATSBY_SANITY_DATASET,
  sanityOverlayDrafts: process.env.SANITY_OVERLAY_DRAFTS?.toLowerCase() === "true",
  sanityReadToken: process.env.SANITY_READ_TOKEN,
  sanityWatchMode: process.env.SANITY_WATCH_MODE?.toLowerCase() === "true",
  sanityApiVersion: process.env.GATSBY_SANITY_APIVERSION ?? "2022-08-29",
  sanityGraphqlTag: process.env.SANITY_GRAPHQL_TAG ?? "default",
  isGroup: false,
  site: process.env.GATSBY_SITE?.toLowerCase() ?? "market",
  baseUrl: process.env.GATSBY_BASE_URL,
  theme: process.env.GATSBY_THEME?.toLowerCase() || "default",
  siteName: !isNullOrWhiteSpace(process.env.GATSBY_SITE_NAME)
    ? process.env.GATSBY_SITE_NAME
    : "Anticimex",
  reCaptchaSiteKey: process.env.GATSBY_RECAPTCHA_SITEKEY,
  useFormHoneypot: process.env.GATSBY_USE_FORM_HONEYPOT?.toLowerCase() === "true", // opt-in for now
  cisionReCaptchaSiteKey: process.env.GATSBY_CISION_RECAPTCHA_SITEKEY,
  googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  tagManagerId: process.env.GATSBY_TAG_MANAGER_ID,
  disclaimerRelayEndpoint: process.env.GATSBY_DISCLAIMER_RELAY_ENDPOINT,
  pageRatingHandlerEndpoint: process.env.GATSBY_PAGE_RATING_HANDLER_ENDPOINT,
  baseLangNotLowerCased: process.env.GATSBY_BASE_LANGUAGE || "en_US",
  baseLang: process.env.GATSBY_BASE_LANGUAGE?.toLowerCase() || "en_us",
  algolia: {
    appId: process.env.GATSBY_ALGOLIA_APP_ID,
    apiKey: process.env.GATSBY_ALGOLIA_API_KEY,
    indexName: process.env.GATSBY_ALGOLIA_INDEX
  },
  defaultMaxFileSize: getMaxFileSize(process.env.GATSBY_DEFAULT_MAX_FILE_SIZE_MB),
  tagManagerDisablePlugin:
    process.env.GATSBY_TAG_MANAGER_DISABLE_PLUGIN?.toLowerCase() === "true",
  tagManagerApplyReferralFix:
    process.env.GATSBY_TAG_MANAGER_APPLY_REFERRAL_FIX?.toLowerCase() === "true",
  gtag: {
    trackingIds: parseTrackingIds(process.env.GATSBY_GTAG_TRACKING_IDS),
    optimizeId: process.env.GATSBY_GTAG_OPTIMIZE_ID
  }
};

siteConfig.isGroup = siteConfig.site === "group";

export default siteConfig;
