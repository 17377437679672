import React, { createContext } from "react";
import { UrlMap, useUrlMap } from "../hooks/useUrlMap";

const LinkContext = createContext<UrlMap>({} as UrlMap);

interface PageContextProviderProps {
  children?: React.ReactNode;
  urlMap?: UrlMap;
}

const LinkContextProvider: React.FC<PageContextProviderProps> = ({ children }) => {
  const urlMap = useUrlMap(); // memoized

  return <LinkContext.Provider value={urlMap}>{children}</LinkContext.Provider>;
};

export { LinkContext, LinkContextProvider };
