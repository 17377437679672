import { graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";

interface SitePageNode {
  path: string;
  context: {
    _id: string;
  };
}

interface SitePageQuery {
  allSitePage?: {
    nodes?: SitePageNode[];
  };
}

export type UrlMap = {
  [id: string]: string;
};

export const useUrlMap = (): UrlMap => {
  const data = useStaticQuery<SitePageQuery>(graphql`
    query SitePageQuery {
      allSitePage {
        nodes {
          path
          context {
            _id
          }
        }
      }
    }
  `);

  const nodes = data?.allSitePage?.nodes;

  const urlMapMemo = useMemo(() => {
    const urlMap: UrlMap = {};

    if (!nodes?.length) {
      console.error("Could not query allSitePage to retrieve a URL map");
      return urlMap;
    }

    for (const node of nodes) {
      if (!node.context?._id?.length || !node.path?.length) {
        continue;
      }

      urlMap[node.context._id] = node.path;
    }

    return urlMap;
  }, [nodes]);

  return urlMapMemo;
};

