import React, { createContext, useEffect, useState } from "react";

/**
 * Used to tell whether the page has been hydrated. Defaults to false.
 */
const HydrationContext = createContext<boolean>(false);

interface HydrationContextProviderProps {
  children?: React.ReactNode;
}

const HydrationContextProvider: React.FC<HydrationContextProviderProps> = ({
  children
}) => {
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
    setHydrated(true);
  }, []);

  return (
    <HydrationContext.Provider value={hydrated}>{children}</HydrationContext.Provider>
  );
};

export { HydrationContext, HydrationContextProvider };
