/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, Dispatch, useMemo, useReducer } from "react";

export enum CourseContextActionTypes {
  SET_LOADING = "SET_LOADING",
  SET_COURSE = "SET_COURSE",
  SET_COURSE_ID = "SET_COURSE_ID",
  SET_COURSE_CATEGORY = "SET_COURSE_CATEGORY",
  SET_COURSE_SLUG = "SET_COURSE_SLUG",
  SET_COURSE_CATEGORY_SLUG = "SET_COURSE_CATEGORY_SLUG",
  SET_SUB_COURSE = "SET_SUB_COURSE",
  SET_COURSES = "SET_COURSES",
  SET_COURSE_LANDING_SLUG = "SET_COURSE_LANDING_SLUG"
}

interface Avatar {
  url: string;
  id: string;
  remove: boolean;
  postData: any | null;
  fileName: string | null;
  fileType: string | null;
  metaAssetId: string | null;
  fileStatus: string | null;
  size: number;
  mimetype: string;
  additionalData: any | null;
}

interface Settings {
  avatarOverlayColor: string | null;
  avatarTextColor: string | null;
  avatarOverlayOpacity: number;
  avatarReposition: any | null;
}

interface Course {
  id: string;
  name: string;
}

interface Statistics {
  students_total: number;
  comments_total: number;
  avg_progress: number;
}

interface Location {
  id: string;
  name: string;
  latitude: string;
  longitude: string;
  place_id: string;
  room: string;
  city: string;
  country: string;
  house_number: string;
  road_long: string;
  language: string;
  address: string;
  // cover_image: string;
}

export enum CourseFormat {
  Online = "e-learning",
  Classroom = "classroom",
  Blended = "blended"
}

export interface CourseItem {
  id: string;
  name: string;
  active: boolean;
  start_date: string;
  end_date: string;
  min_participants: number | null;
  max_participants: number | null;
  format: CourseFormat;
  avatar: Avatar;
  settings: Settings;
  course: Course;
  catalog: boolean;
  statistics: Statistics;
  description: string | null;
  tags: any | null;
  categories: any | null;
  levels: any | null;
  skills: any | null;
  types: any | null;
  subjects: any | null;
  languages: any | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  price?: number;
  location?: Location;
  currency?: string;
}

interface CoursesState {
  course: {
    id: string;
    name: string;
    childCourses: CourseItem[];
  } | null;
  subCourse: CourseItem | null;
  courseId: string | null;
  courseCategory: string | null;
  courseSlug: string | null;
  courseCategorySlug: string | null;
  courseLandingSlug: string | null;
  loading?: boolean;
  coursesList?: Array<{
    id: string;
    name: string;
    childCourses: CourseItem[];
  }>;
}

type CoursesContextType = {
  state: CoursesState;
  dispatch: Dispatch<
    Partial<CoursesState> & {
      type: CourseContextActionTypes;
    }
  >;
};

const initialCoursesState = {
  course: null,
  courseId: null,
  courseCategory: null,
  courseSlug: null,
  courseCategorySlug: null,
  courseLandingSlug: null,
  subCourse: null,
  loading: false,
  coursesList: []
};

const coursesReducer = (
  state: CoursesState,
  action: Partial<CoursesState> & {
    type: CourseContextActionTypes;
  }
) => {
  const result = { ...state };

  switch (action.type) {
    case CourseContextActionTypes.SET_COURSE:
      result.course = action.course!;
      break;

    case CourseContextActionTypes.SET_COURSE_ID:
      result.courseId = action.courseId ?? null;
      break;

    case CourseContextActionTypes.SET_COURSE_CATEGORY:
      result.courseCategory = action.courseCategory ?? null;
      break;

    case CourseContextActionTypes.SET_COURSE_SLUG:
      result.courseSlug = action.courseSlug ?? null;
      break;

    case CourseContextActionTypes.SET_COURSE_CATEGORY_SLUG:
      result.courseCategorySlug = action.courseCategorySlug ?? null;
      break;

    case CourseContextActionTypes.SET_SUB_COURSE:
      result.subCourse = action.subCourse ?? null;
      break;

    case CourseContextActionTypes.SET_LOADING:
      result.loading = action.loading;
      break;

    case CourseContextActionTypes.SET_COURSES:
      result.coursesList = action.coursesList ?? [];
      break;

    case CourseContextActionTypes.SET_COURSE_LANDING_SLUG:
      result.courseLandingSlug = action.courseLandingSlug ?? null;
      break;

    default:
      return result;
  }

  return result;
};

const CoursesContext = createContext<CoursesContextType>({
  state: initialCoursesState,
  dispatch: () => null
} as CoursesContextType);

const CoursesContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(coursesReducer, initialCoursesState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <CoursesContext.Provider value={contextValue}>{children}</CoursesContext.Provider>
  );
};

const listAllCourses = async dispatch => {
  dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: true });
  try {
    const response = await fetch(
      `${process.env.GATSBY_AZURE_COURSES_API_URL}/courses/list`
    );

    if (response.ok) {
      const json = await response.json();
      dispatch({
        type: CourseContextActionTypes.SET_COURSES,
        coursesList: json
      });
      dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: false });
    } else {
      dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: false });
    }
  } catch (error) {
    console.error("Failed to fetch course data:", error);
    dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: false });
    dispatch({ type: CourseContextActionTypes.SET_COURSES, coursesList: [] });
  }
};

const resetCoursesState = dispatch => {
  dispatch({ type: CourseContextActionTypes.SET_COURSE, course: null });
  dispatch({ type: CourseContextActionTypes.SET_COURSE_ID, courseId: null });
  dispatch({ type: CourseContextActionTypes.SET_COURSE_CATEGORY, courseCategory: null });
  dispatch({ type: CourseContextActionTypes.SET_COURSE_SLUG, courseSlug: null });
  dispatch({
    type: CourseContextActionTypes.SET_COURSE_CATEGORY_SLUG,
    courseCategorySlug: null
  });
  dispatch({ type: CourseContextActionTypes.SET_SUB_COURSE, subCourse: null });
  dispatch({ type: CourseContextActionTypes.SET_LOADING, loading: false });
};

export { listAllCourses, resetCoursesState };
export { CoursesContext, CoursesContextProvider };
