import React, { createContext } from "react";
import siteConfig from "../config/siteConfig";
import { GatsbyPageContext } from "../models/pages/GatsbyPageContext";

const PageContext = createContext<GatsbyPageContext>({} as GatsbyPageContext);

interface PageContextProviderProps {
  children?: React.ReactNode;
  pageContext: GatsbyPageContext;
}

const PageContextProvider: React.FC<PageContextProviderProps> = ({
  pageContext,
  children
}) => (
  <PageContext.Provider
    value={{
      ...pageContext,
      // Here it is safe to lower-case i18nLang, as this is a copy of the pageContext used in page template queries
      i18nLang: pageContext.i18nLang?.toLowerCase() ?? siteConfig.baseLang
    }}
  >
    {children}
  </PageContext.Provider>
);

export { PageContext, PageContextProvider };
