export function isNullOrWhiteSpace(
  input: string | undefined | null
): input is undefined | null {
  return !input || !input.trim();
}

export function truncateString(str, num) {
  return str?.length > num ? str.slice(0, num > 3 ? num - 3 : num) + "..." : str;
}

export const normalizeText = (value: string | undefined): string | undefined =>
  value
    ?.normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();

export const isValidEmail = (email: string): boolean => /^\S+@\S+\.\S+$/.test(email);

export const isPositiveNumber = (value: string): boolean => /^\d+$/.test(value);

export const isValidPostalCode = (value: string): boolean =>
  isPositiveNumber(value) && value?.length === 5;

export const stripNonNumber = (value: string): string => value?.replace(/\D/g, "");

/**
 * Replaces occurrences of keys from replacementMap in the value argument with their
 * corresponding value in replacementMap.
 * @param value
 * @param replacementMap
 * @returns
 */
export const replaceWithUnicode = (
  value: string | undefined,
  replacementMap: Record<string, string> = {
    "&shy;": "\u00AD"
  }
) =>
  value
    ? Object.keys(replacementMap).reduce(
        (acc, key) => acc.replace(new RegExp(key, "g"), replacementMap[key]),
        value
      )
    : undefined;
