import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useMemo,
  useReducer
} from "react";

interface DialogState {
  marketSelector: boolean;
}

interface DialogContextType {
  state: DialogState;
  dispatch: Dispatch<{ type: string }>;
}

const initialDialogState = {
  marketSelector: false
};

const dialogReducer = (state: DialogState, action: { type: string }) => {
  const result = { ...state };

  switch (action.type) {
    case "marketSelector.open":
      result.marketSelector = true;
      break;
    case "marketSelector.close":
      result.marketSelector = false;
      break;
    default:
      return result;
  }

  return result;
};

// Dummy defaultValue is required here for "gatsby build" to succeed. The "real" initial value is set below
// with useReducer though.
const DialogContext = createContext<DialogContextType>({
  state: initialDialogState
} as DialogContextType);

const DialogContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(dialogReducer, initialDialogState);

  // TODO: Investigate useMemo
  const dialogContextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <DialogContext.Provider value={dialogContextValue}>{children}</DialogContext.Provider>
  );
};

export { DialogContext, DialogContextProvider };
