import { ClientConfig, createClient } from "@sanity/client";
import siteConfig from "../config/siteConfig";

export const sanityClientConfig: ClientConfig = {
  apiVersion: siteConfig.sanityApiVersion,
  dataset: siteConfig.sanityDataset,
  projectId: siteConfig.sanityProjectId,
  token:
    process.env.GATSBY_IS_PREVIEW === "true"
      ? // Note: We can't use siteConfig.sanityReadToken here, as it's not available in browser context.
        // Mainly used in preview at the moment to fetch updates from Sanity.
        process.env.GATSBY_SANITY_READ_TOKEN
      : // For public access, don't expose token.
        siteConfig.sanityReadToken,
  useCdn: false
};

export const sanityClient = createClient(sanityClientConfig);
